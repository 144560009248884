@import "../variables/Variables.scss";

.big-full-fretboard-container {
    margin-top: 5rem;
    margin-bottom: 1rem;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-bottom: 0px;
    max-width: 98vw;
}

.big-full-fretboard-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 0px;
}

.ff-fret0 {
    display: flex;
    flex-direction: column;
    min-height: 12rem;
    width: 2rem;
    min-width: 2rem;
    justify-content: space-around;
    border-right:  4px $brand-purple solid;
}

.ff-fret1 {
    display: flex;
    flex-direction: column;
    min-height: 12rem;
    width: 5rem;
    min-width: 5rem;
    border: 1px $fret-bar-color solid;
    justify-content: space-around;
    font-size: small;
}
.ff-fret2 {
    display: flex;
    flex-direction: column;
    min-height: 12rem;
    width: 5rem;
    min-width: 5rem;
    border: 1px $fret-bar-color solid;
    justify-content: space-around;
    font-size: small;
}
.ff-fret3 {
    display: flex;
    flex-direction: column;
    min-height: 12rem;
    width: 5rem;
    min-width: 5rem;
    border: 1px $fret-bar-color solid;
    justify-content: space-around;
    font-size: small;
}
.ff-fret4 {
    display: flex;
    flex-direction: column;
    min-height: 12rem;
    width: 5rem;
    min-width: 5rem;
    border: 1px $fret-bar-color solid;
    justify-content: space-around;
    font-size: small;
}
.ff-fret5 {
    display: flex;
    flex-direction: column;
    min-height: 12rem;
    width: 5rem;
    min-width: 5rem;
    border: 1px $fret-bar-color solid;
    justify-content: space-around;
    font-size: small;
}
.ff-fret6 {
    display: flex;
    flex-direction: column;
    min-height: 12rem;
    width: 5rem;
    min-width: 5rem;
    border: 1px $fret-bar-color solid;
    justify-content: space-around;
    font-size: small;
}
.ff-fret7 {
    display: flex;
    flex-direction: column;
    min-height: 12rem;
    width: 5rem;
    min-width: 5rem;
    border: 1px $fret-bar-color solid;
    justify-content: space-around;
    font-size: small;
}
.ff-fret8 {
    display: flex;
    flex-direction: column;
    min-height: 12rem;
    width: 5rem;
    min-width: 5rem;
    border: 1px $fret-bar-color solid;
    justify-content: space-around;
    font-size: small;
}
.ff-fret9 {
    display: flex;
    flex-direction: column;
    min-height: 12rem;
    width: 5rem;
    min-width: 5rem;
    border: 1px $fret-bar-color solid;
    justify-content: space-around;
    font-size: small;
}
.ff-fret10 {
    display: flex;
    flex-direction: column;
    min-height: 12rem;
    width: 5rem;
    min-width: 5rem;
    border: 1px $fret-bar-color solid;
    justify-content: space-around;
    font-size: small;
}
.ff-fret11 {
    display: flex;
    flex-direction: column;
    min-height: 12rem;
    width: 5rem;
    min-width: 5rem;
    border: 1px $fret-bar-color solid;
    justify-content: space-around;
    font-size: small;
}
.ff-fret12 {
    display: flex;
    flex-direction: column;
    min-height: 12rem;
    width: 5rem;
    min-width: 5rem;
    border: 1px $fret-bar-color solid;
    justify-content: space-around;
    font-size: small;
}
.ff-fret13 {
    display: flex;
    flex-direction: column;
    min-height: 12rem;
    width: 5rem;
    min-width: 5rem;
    border: 1px $fret-bar-color solid;
    justify-content: space-around;
    font-size: small;
}
.ff-fret14 {
    display: flex;
    flex-direction: column;
    min-height: 12rem;
    width: 5rem;
    min-width: 5rem;
    border: 1px $fret-bar-color solid;
    justify-content: space-around;
    font-size: small;
}
.ff-fret15 {
    display: flex;
    flex-direction: column;
    min-height: 12rem;
    width: 5rem;
    min-width: 5rem;
    border: 1px $fret-bar-color solid;
    justify-content: space-around;
    font-size: small;
}
.ff-fret16 {
    display: flex;
    flex-direction: column;
    min-height: 12rem;
    width: 5rem;
    min-width: 5rem;
    border: 1px $fret-bar-color solid;
    justify-content: space-around;
    font-size: small;
}
.ff-fret17 {
    display: flex;
    flex-direction: column;
    min-height: 12rem;
    width: 5rem;
    min-width: 5rem;
    border: 1px $fret-bar-color solid;
    justify-content: space-around;
    font-size: small;
}
.ff-fret18 {
    display: flex;
    flex-direction: column;
    min-height: 12rem;
    width: 5rem;
    min-width: 5rem;
    border: 1px $fret-bar-color solid;
    justify-content: space-around;
    font-size: small;
}
.ff-fret19 {
    display: flex;
    flex-direction: column;
    min-height: 12rem;
    width: 5rem;
    min-width: 5rem;
    border: 1px $fret-bar-color solid;
    justify-content: space-around;
    font-size: small;
}
.ff-fret20 {
    display: flex;
    flex-direction: column;
    min-height: 12rem;
    width: 5rem;
    min-width: 5rem;
    border: 1px $fret-bar-color solid;
    justify-content: space-around;
    font-size: small;
}
.ff-fret21 {
    display: flex;
    flex-direction: column;
    min-height: 12rem;
    width: 5rem;
    min-width: 5rem;
    border: 1px $fret-bar-color solid;
    justify-content: space-around;
    font-size: small;
}
.ff-fret22 {
    display: flex;
    flex-direction: column;
    min-height: 12rem;
    width: 5rem;
    min-width: 5rem;
    border: 1px $fret-bar-color solid;
    justify-content: space-around;
    font-size: small;
}
.ff-fret23 {
    display: flex;
    flex-direction: column;
    min-height: 12rem;
    width: 5rem;
    min-width: 5rem;
    border: 1px $fret-bar-color solid;
    justify-content: space-around;
    font-size: small;
}
.ff-fret24 {
    display: flex;
    flex-direction: column;
    min-height: 12rem;
    width: 5rem;
    min-width: 5rem;
    border: 1px $fret-bar-color solid;
    justify-content: space-around;
    font-size: small;
}

.highlighted-note-root {
    background-color: rgb(154, 156, 252);
    font-size: small;
    height: 0.4rem;
    line-height: 0.4rem;
}

.highlighted-note-root-no-note-name {
    background-color: #1B9AAA;
    font-size: 0px;
    height: 0.4rem;
    line-height: 0.4rem;
    box-shadow: 0 0.35rem 0 0 #1B9AAA,0 -.35rem 0 0 #1B9AAA, 0 0.5rem 0 0 #1B9AAA,0 -.5rem 0 0 #1B9AAA;
}

.highlighted-note-third {
    background-color: rgb(61, 231, 146);
    font-size: small;
    height: 0.4rem;
    line-height: 0.4rem;
}

.highlighted-note-third-no-note-name {
    background-color: #FFC43D;
    height: 0.4rem;
    line-height: 0.4rem;
    font-size: 0px;
    box-shadow: 0 0.02rem 0 0 #FFC43D,0 -.02rem 0 0 #FFC43D;
}

.highlighted-note-fifth {
    background-color: rgb(187, 43, 43);
    font-size: small;
    height: 0.4rem;
    line-height: 0.4rem;
}

.highlighted-note-fifth-no-note-name {
    background-color: #EF476F;
    height: 0.4rem;
    line-height: 0.4rem;
    font-size: 0px;
    box-shadow: 0 0.25rem 0 0 #EF476F,0 -.25rem 0 0 #EF476F;
}

.regular-note-div {
    background-color: rgb(221, 221, 221);
    font-size: small;
    height: 0.4rem;
    line-height: 0.5rem;
}

.regular-note-div-no-note-name {
    background-color: rgb(214, 214, 214);
    font-size: 0px;
    height: 0.4rem;
    line-height: 0.4rem;
}

.big-full-fretboard-fret-numbers-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    
}

.fret-number-div {    
    display: flex;
    flex-direction: column;
    min-height: 1rem;
    width: 5rem;
    min-width: 5rem;
    justify-content: space-around;
    font-size: x-small;
}

.fret-number-div-0fret {
    width: 2rem;
    min-width: 2rem;
}


.fret-number-dot-div {  
    width: 1rem;
    border-radius: 50%;
    color: #fff;
    text-align: center;
    background: #686868;
    align-self: center;
    margin-bottom: 4px;
}

.ff-chord-type-selection-area {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    font-size: small;
    margin-bottom: 1rem;
}
.ff-note-names-selection{
    margin-left: 4rem;
    margin-right: 4rem;
}

.ff-submit-button {
    cursor: pointer;
}

.ff-submit-button:hover {
    color: $brand-purple
}

.ff-clear-button {
    cursor: pointer;
    margin-left: 2rem;
}

.ff-clear-button:hover {
    color: $brand-purple
}

.below-ff-change-selectors {
    background-color: $background-color;
    border-top: 1px solid $text-color;
    border-bottom: 0px solid #424242;
    border-left: 0px solid #424242;
    border-right: 0px solid #424242;
}

.ff-note-names-selection-title{
    margin-bottom: .5rem;
}

.ff-key-selection-title {
    margin-bottom: .5rem;
}

.hidden-h1 {
    display: none;
}