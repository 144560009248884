.about-pent-page-container-div{
    padding: 2rem;
}

.about-pent-page-container-div-header{
    margin-bottom: 2rem;
}

// medium devices
@media only screen and (min-width: 768px){
    .about-pent-page-container-div{
        padding: 8rem;
    }
}