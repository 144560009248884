@import "../variables/Variables.scss";
body {
    background-color: $background-color;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif sans-serif;
}

.active-note-5th {
    background-color: #EF476F;
    height: 0.4rem;
    line-height: 0.5rem;
    box-shadow: 0 0.25rem 0 0 #EF476F,0 -.25rem 0 0 #EF476F;
}

.active-note-3rd {
  background-color: #FFC43D;
  height: 0.4rem;
  line-height: 0.5rem;

  box-shadow: 0 0.02rem 0 0 #FFC43D,0 -.02rem 0 0 #FFC43D;
}


.active-note {
  background-color: #1B9AAA;
  height: 0.4rem;
  line-height: 0.5rem;
  box-shadow: 0 0.35rem 0 0 #1B9AAA,0 -.35rem 0 0 #1B9AAA, 0 0.5rem 0 0 #1B9AAA,0 -.5rem 0 0 #1B9AAA;
}

.pentatonic-note {
  background-color: #d6d6d6;
}

.selection-div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: $light-grey;
    width: 850px;
    padding-top: 1.2em;
    padding-bottom: 0.5em;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    line-height: 1rem;
}

.selection-div-4f {
  display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: $light-grey;
    width: 570px;
    padding-top: 1.2em;
    padding-bottom: 0.5em;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    line-height: 1rem;
}

@media (max-width: 700px){
  /* CSS that should be displayed if width is equal to or less than 991px goes here */
  .selector-select-class {
    margin: 0px 1rem 0px 1rem!important;
  }

  .submit-div {
    align-items: center;
  }

  .selection-div-4f{
    width: 100%;
  }

 }

.selection-div-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
}

.chord-selection-area{
    display: flex;
    flex-direction: column;
    min-width: 10rem;
}

.submit-div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: 1rem;
}

.submit-button{
    cursor: pointer;
    background-color: $light-grey;
    color: $text-color;
    justify-content: center;
    width: 5rem;
    border-radius: 0.2rem;
    margin-bottom: 10px;
    // border: 1px solid #424242;
    // max-height: 1.7rem;
}

.submit-button:hover {
    color: $brand-purple;
}

.chord-picker-sel{
    background-color: $light-grey;
    cursor: pointer;
}

.pentatonic-picker-sel{
  background-color: $light-grey;
  cursor: pointer;

}

.pentatonic-selection-area{
    display: flex;
    flex-direction: column;
    min-width: 10rem;
}

.fretboard-container {
  display: flex;
  justify-content: center;
}

.fretboard-container .fret {
  border: 2px solid  $light-grey;
  height: 12rem;
  display: flex;
  flex-direction: column;

}

.fretboard-container .string {
  border: 4px solid $background-color;
  flex: 1;
  border-radius: .4rem;
}

.fret0 {
  width: 150px;
}

.fret1 {
  width: 150px;
}

.fret2 {
  width: 145px;
}

.fret3 {
  width: 140px;
}

.fret4 {
  width: 135px;
}

.fret5 {
  width: 130px;
}

.key-choice-dropdown-div select {
  width: auto;
  font-size: small;
  background-color: $light-grey;
  color: $text-color;
  border-radius: 0%;
  border-top: 1px solid $text-color;
  border-bottom: 0px solid #424242;
  border-left: 0px solid #424242;
  border-right: 0px solid #424242;
  margin-left: 2rem;
  margin-right: 2rem;
  cursor: pointer;
}

.major-minor-choice-dropdown-div select {
  font-size: small;
  background-color: $light-grey;
  color: $text-color;
  border-radius: 0%;
  border-top: 1px solid $text-color;
  border-bottom: 0px solid #424242;
  border-left: 0px solid #424242;
  border-right: 0px solid #424242;
  margin-left: 2rem;
  margin-right: 2rem;
  cursor: pointer;
}

.shape-choice-dropdown-div select {
  font-size: small;
  background-color: $light-grey;
  color: $text-color;
  border-radius: 0%;
  border-top: 1px solid $text-color;
  border-bottom: 0px solid #424242;
  border-left: 0px solid #424242;
  border-right: 0px solid #424242;
  margin-left: 2rem;
  margin-right: 2rem;
  cursor: pointer;
}



