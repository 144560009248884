@import "../variables/Variables.scss";
.add-fretboard-button{
    cursor: pointer;
    background-color: $light-grey;
    color: $text-color;
    justify-content: center;
    width: 10rem;
    height: 2rem;
    border-radius: 0.2rem;
    border: 0px;
    margin-top: 2rem;
    padding-bottom: 4px;
    box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px, rgba(255, 255, 255, 0.25) 0px 1px 0px inset;
}

.remove-fretboard-button {
    cursor: pointer;
    background-color: $light-grey;
    color: $text-color;
    width: 5rem;
    height: 2rem;
    border-radius: 0.2rem;
    border: 0px;
    margin-left: 2rem
}

.advanced-settings-button-div {
    display: flex;
    justify-content: right;
    margin-top: 1rem;
    margin-right: 1rem;
    margin-bottom: 0.2em;
}

.advanced-settings-button:hover {
    background-color: $brand-purple;
    color: white;
}

.frets-selection-in-modal{
    display: flex;
    flex-direction: row;
    justify-content: left;
}

.frets-text-in-modal{
    font-size: large;
    margin-left: 2rem;
}

.button-group-frets-in-modal{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: 4rem;
}

button {
    min-width: 5rem;
}

.fretboard-view-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 2em;
    padding-bottom: 0.5em;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    line-height: 1rem;
}
  
.footer-big-div{
    display: flex;
    flex-direction: row;
    justify-content: center
}
  
.footer-little-div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: $light-grey;
    min-height: 2.0rem;
    align-items: center;
    border-radius: 3px;
}


.active-note-key-example {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: #1B9AAA;
    font-size:x-small;
    width: 4rem;
    height: 1rem;
    margin-left: 0.3rem;
    margin-right: 0.3rem;
    border-radius: .2rem;
}
  
.active-note-5th-key-example {
    background-color: #EF476F;
    font-size: x-small;
    width: 4rem;
    height: 1rem;
    margin-left: 0.3rem;
    margin-right: 0.3rem;
    border-radius: .2rem;
}
  
.active-note-3rd-key-example {
    background-color: #FFC43D;
    font-size: x-small;
    width: 4rem;
    height: 1rem;
    margin-left: 0.3rem;
    margin-right: 0.3rem;
    border-radius: .2rem;
}

.settings-and-key-div {
    display: flex;
    flex-direction: row;
    justify-content: end;
}

.new-feature-banner {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: $brand-purple;
    color: white;
    font-size: 1.2rem;
    padding: 0.5rem;
    border-radius: 10px;
    width: 24rem;
    cursor: pointer;
}

.new-feature-banner-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 10rem;
    margin-bottom: 1rem;
}