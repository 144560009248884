.privacy-policy-container {
    margin: 2rem;
}

.updated-date-p{
    margin-bottom: 4rem
}

// medium devices
@media only screen and (min-width: 768px){
    .privacy-policy-container{
        padding-left: 8rem;
        padding-right: 8rem;
    }
}

