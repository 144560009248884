nav {
  background-color: #dddddd;
}

.nav-bs-container button {
  border: 0px;
}

.nav-link:hover {
  color: #1B9AAA;
}

.nav-link {
  font-size: small;
}

.navbar-brand:hover {
  color: #1B9AAA;
}

.nav-guitar-div {
  text-align: left;
  font-size: small;
  margin-bottom: -0.4rem;
}

.nav-theory-div {
  text-align: left;
  font-size: small;
}

.nav-theory-div::after {
  content: ".com";
  color: #1B9AAA;
}

.nav-bs-container {
  margin-left: 1rem;
  width: 100%;
}/*# sourceMappingURL=NavBar.css.map */